.resource-buttons{
    padding: 20px;
    padding-top: 25px;
    padding-bottom: 25px;
}

.resource-btn{
    padding: 8px !important;
    padding-right: 16px !important;
    padding-left: 16px !important;
    color: #000000 !important;
    font-size: 10px !important;
    font-weight: 600 !important;
    font-family: "Open Sans", sans-serif !important;
    line-height: 13.62px !important;
    border-radius: 64px !important;
    width: 95%;
}

.fully-occ-btn{
    background-color: #02FAB8 !important;   
}

.partial-occ-btn{
    background-color: #FFDE30 !important;   
}

.free-occ-btn{
    background-color: #FF859B !important;   
    padding-right: 12px !important;
    padding-left: 12px !important;
}

.btn-active{
    filter: brightness(70%);
}

.resource-count{
    background-color: #FFFFFF;
    padding: 4px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 10px;
    font-weight: 600;
    border-radius: 24px;
    margin-left: 10px;

}

.resourse-search-stack{
    border-bottom: none !important;
    border: 1px solid #CCCCCC;
    border-radius: 5px;
    height: 40px !important;
    padding: 5px !important;
    width: 95%;

}

.resource-search{    
    border: none !important;
    border: 1px solid #CCCCCC !important;
    width: 95% !important;
    height: 50px !important;
    border-radius: 12px;
    margin-right: 10px !important;
    padding: 12px !important;
    color: black !important;    
    font-family: "Open Sans", serif !important;  
}

.add-resource{
    background-color: #676DFA !important;
    /* font-family: "Open Sans", sans-serif !important; */
    font-family: "Open Sans", serif !important; 
    font-weight: 600;
    font-size: 14px;
    line-height: 19.07px;
    color: white !important;
    text-transform: capitalize;
    padding: 8px;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    height: 55px;
    border-radius: 12px !important;
}

.add-resource:hover{
    background-color: #b0b2fb !important;
}

.add-resource-anchor{
    width: 520px !important;
    padding: 40px;
    font-family: "Open Sans", serif !important; 
}

.add-resource-text{
    font-family: "Open Sans", serif !important; 
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 28.13px !important;
}

.resource-form{
    margin-top: 15px;
}

.resource-usage{
    color: orange;
}

.add-members{
    background-color: #F8B6001A !important;
    font-family: "Open Sans", sans-serif !important;
    font-weight: 600;
    font-size: 14px;
    line-height: 19.07px;
    border: 1px solid #000000 !important;
    color: #000000 !important;
    padding: 8px;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    height: 55px;
    border-radius: 12px !important;
}

.add-resources{
    background-color: #676DFA !important;
    font-family: "Open Sans , serif" !important;    
    font-size: 14px;
    line-height: 15px;
    text-transform: capitalize;
    color: #ffffff !important;
    padding: 8px;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    height: 40px;
    text-transform: none !important;
    border-radius: 12px !important;
}

.resource-usage-text{
    margin-left: 20px !important;
}

.fieldBox{
    margin-bottom: 20px;
}

.labelText{
    font-family: "Open Sans", sans-serif !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 19.07px !important;
    margin-bottom: 10px !important;
}

.textField{
    width: 100%;
    /* font-family: "Open Sans", sans-serif !important; */
    font-family: "Open Sans", serif !important;  
}

.buttonBox{
    margin-top: 70px;
}

.resource-detail{
    width: 100%;
    min-height: 80vh;
    background-color: #FFFFFF;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
    border-radius: 10px;
    padding: 50px;
    padding-top: 70px;
}

.stack-title{
    color: #FFFFFF;
    font-family: "Open Sans", sans-serif !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.79px;
    text-align: left;
}

.project-title{
    font-family: "Open Sans", serif !important; 
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: 25px !important;    
    margin-bottom: 20px !important;
}

.current-project-title{
    font-family: "Open Sans", serif !important; 
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: 25px !important; 
    margin-top: 40px !important;   
    margin-bottom: 10px !important;

}

.current-project-row{
    margin-top: 40px;
}

.projects-title{
    font-family: "Open Sans", sans-serif !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 21.79px !important;    
}

.projects-timeline{
    margin-top: 10px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 19.07px !important;
    font-family: "Open Sans", sans-serif !important;
}

.table-cell-stack-name{
    background-color: #CCCCCC;
    padding: 4px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 24px;
    font-weight: 600;
}

.table-section{
    margin-top: 30px;
}

.search-field{
    height: 50px !important;
    width: 95% !important;
    border-radius: 12px !important;
    padding: 0px !important;
}

.table-cell-row{
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 40px !important;
    font-family: "Open Sans", serif !important;   
    padding-top: 10px !important;
    padding-bottom: 10px !important;  
    color: #000000;
    opacity: 80%;    
    padding-left: 0 !important;
}

.table-cell-row-name{
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 40px !important;
    font-family: "Open Sans", serif !important;   
    padding-top: 10px !important;
    padding-bottom: 10px !important;  
}

.resource-card{
    height: 191px;
    width: 95%;    
    border-radius: 10px;
    cursor: pointer;
}

.resource-card:hover{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.avatar-name{
    margin: auto !important;
    width: 100px !important;       
    height: 95px !important;
    text-align: center !important;    
}

.resource-name{
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    margin-bottom: 10px;
    font-family: "Open Sans", serif !important; 
}

.resource-designation{
    font-size: 12px;
    font-family: "Open Sans", serif !important; 
    font-weight: 400;
    line-height: 14px;
    color: #000000;
    opacity: 80%;
}

.resource-card-detail{
    padding: 20px;
}

.resource-avatar{
    width: 60px;
    height: 60px;
}

.resource-name-dash{
    display: flex;
}

.rsc-name{
    margin-left: 20px;
    font-size: 20px;
    font-weight: 600;
    font-family: "Open Sans , serif" !important;
    line-height: 24px;
}

.rsc-designation{
    margin-top: 5px;
    font-size: 16px;
    font-weight: 400;
    font-family: "Open Sans , serif" !important;
    color: #000000;
    opacity: 80%;
}

.rsc-edit-btn{
    float: right;
    background-color: #676DFA !important;
    font-family: "Open Sans", sans-serif !important;
    font-weight: 600;
    font-size: 14px;
    line-height: 19.07px;
    color: white !important;
    text-transform: capitalize;
    padding: 8px;
    padding-left: 6px;
    padding-right: 6px;
    width: 45%;
    height: 45px;
    border-radius: 12px !important;
}

.rsc-delete-btn{
    float: right;
    background-color: #c91616 !important;
    font-family: "Open Sans", sans-serif !important;
    font-weight: 600;
    font-size: 14px;
    line-height: 19.07px;
    color: white !important;
    text-transform: capitalize;
    padding: 8px;
    padding-left: 6px;
    padding-right: 6px;
    width: 45%;
    height: 45px;
    border-radius: 12px !important;
}


.rsc-availability-sec{
    padding: 20px;
    margin: 20px;
    border-radius: 12px;
    border: 1px solid #CCCCCC;
    height: 85%;
}

.rsc-personal-info-sec{
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 12px;
    border: 1px solid #CCCCCC;
}

.rsc-info-sec{
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.box-heading{
    font-size: 19px;
    font-weight: 600;
    line-height: 19px;
    font-family: "Open Sans , serif" !important;
}

.box-sec{
    margin-top: 20px;
}

.box-label{
    font-weight: 600;
}
.box-text{
    margin-top: 5px;
}

.rsc-status-perm{
    color: white;
    background-color: rgb(205, 249, 205);
    border: 1px solid green;
    padding: 6px;
    color: green;
    font-weight: 700;
    border-radius: 5px;
}

.rsc-status-prob{
    color: white;
    background-color: red;
    padding: 5px;
    border-radius: 15px;
}

.rsc-stack-sec{
    margin-top: 30px;
}

.rsc-stack{
    background-color: #57fcdb;
    padding: 7px;
    border-radius: 7px;
    margin-top: 10px;
    margin-right: 10px;
}

.rsc-proj-li{
    margin-top: 30px;
}

.proj-li{
    font-size: 16px;
    margin-bottom: 10px;
}

.rsc-add-btn{
    float: left;
    width: 20%;
}

.box-textfield{
    width: 90%;
    margin-top: 10px !important;
    color: #000000;
}

.box-checkbox{
    margin-top: 10px;    
}

.field-update{
    width: 100%;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}