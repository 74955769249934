.search{
    width: 100%;
    .MuiOutlinedInput-root {
    color: #00000099;
    height: 48px;


    .MuiOutlinedInput-notchedOutline{
        border-color: #00000033;
        border-width: 1px;
        border-radius: 10px;
    };
    .MuiInputLabel-outlined {
        color: #00000033
        }
}}



.calendar-container{
    margin-top: 20px;
    background-color: white;
}

.tab-header{
    font-size:20px;
    font-weight:600;
    line-height:24px
}

.selected-tab{
    font-size:20px;
    font-weight:600;
    line-height:24px;
    background-color: 'black' ;
}

.selcted-row{
    background-color: 'red' !important;
    background: fixed;
    color: aliceblue;
    background-color: #23005b;
}

.not-selected-row{
    background-color: #f7f2ff !important;
}