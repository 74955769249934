.appBar{
    background-color: #000000 !important;
    border-radius: 10px;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 10px;
}

.mainApp{
padding: 30px;
background-color: #F7F7F7;
}

.app-logo-section{
    margin-right: 30px;
}

.pagesBtn{
    font-size: 18px !important;
    font-family: "Barlow Condensed", serif !important;
    font-weight: 400 !important;
    line-height: 19.07px !important;
    text-transform: capitalize !important;
}

.titleBar{
    font-family: "Open Sans", serif;
    font-size: 28px;
    font-weight: 600;
    line-height: 33.6px;
    color: #000000;
    letter-spacing: -0.05em;
    
}

.dash-container{
    padding-top: 33px;
    flex: auto;
}

.dashboard-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.css-ueukts-MuiButtonBase-root-MuiToggleButton-root.Mui-selected{
    background-color: #000000;
    color: #FFFFFF;
}

.page-container{
    background-color: #F7F7F7;
}

.card-section{
    padding-top: 33px;
    background-image: linear-gradient(#000000, #676DFA);
    height: 180px;
}


.cards-container{
    background-color:white;    
    margin-top:20px;
    border-radius:20px;
    gap:15px;
}

.card-data{
    display: grid;
    align-items: center;
    margin-left: 20px;
    margin-top: 20%;
}

.proj-card-data{
    display: grid;
    align-items: center;
    margin-left: 20px;
    margin-top: 20px;
}
.util-card-data{
    display: grid;
    align-items: center;
    height: 100%;
}

.card-title{
    font-family: "Open Sans", serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
}

.card-hrs{
    font-family: "Open Sans", serif;
    font-size: 28px;
    font-weight: 600;
    line-height: 33.6px;
}

.utilization-card{
    height: 200px !important;
    display: block;
    border-radius: 20px !important;
    background-color: #F7F7F7 !important;
    padding: 20px
}

.actual-hrs-card{
    height: 200px !important;
    display: block;
    border-radius: 20px !important;
    background-color: #5257C71A !important;
    padding: 18px
}

.overtime-hrs-card{
    height: 200px !important;
    display: block;
    border-radius: 20px !important;
    background-color: #F8B6001A !important;
    padding: 18px
}

/* .card-title{
    font-family: "Open Sans", serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 23.44px;
} */

.card-percentage{
    font-family: "Open Sans", serif;
    font-size: 40px;
    font-weight: 900;
    line-height: 46.88px;
    /* margin-top: 27px; */
    color: #444444;
}

.card-text{
    font-family: "Open Sans", serif !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;    
}

.tables-section{
    margin-top: 30px;
    border: none !important;
}

.table-text{
    font-size: 20px;
    font-weight: 600;
    line-height: 23.44px;
    font-family: "Open Sans", serif;
    margin-bottom: 40px !important; 
    margin-top: 30px !important;
}

.table-header-cell{
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 19.2px !important;
    font-family: "Barlow Condensed", serif !important;   
    border-bottom: none  !important;
    border: none !important;
    letter-spacing: -0.05em;
    text-align: left !important;
    padding: 0 !important;
    padding-top: 20px !important;


}

/* .table-header{
    background-color: #F5F5F5;
} */

.table-cell{
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 16.41px !important;
    font-family: "Open Sans" , serif !important;   
    padding-top: 10px !important;
    padding-bottom: 10px !important;  
    padding-left: 0px !important;
}

.table-cell-resource{
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 16.41px !important;
    font-family: "Open Sans", serif !important; 
    display: flex;
    align-items: center;        
}

.resource-cell{
    display:flex;
    align-items:center;
    gap:15px;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 19.2px !important;
}

.percentage-value{
    background-color: #676DFA;
    border-radius: 24px;
    padding: 4px;
    color: white;
    padding-left: 12px;
    padding-right: 12px;
    margin-right: 7px;

}
.stack-value{
    background-color: #B9FDEB;
    border-radius: 24px;
    padding: 4px;
    padding-left: 12px;
    padding-right: 12px;
    margin-right: 7px;

}

.full-value{
    background-color: #02FAB8;
    border-radius: 24px;
    padding: 4px;
    padding-left: 12px;
    padding-right: 12px;
}
.partial-value{
    background-color: #FFDE30;
    border-radius: 24px;
    padding: 4px;
    padding-left: 12px;
    padding-right: 12px;
}

.free-value{
    background-color: #FF859B;
    border-radius: 24px;
    padding: 4px;
    padding-left: 12px;
    padding-right: 12px;
}

.appbar-title{
    font-family: "Open Sans", serif !important;
    font-size: 17px;
    font-weight: 600;
    line-height: 1px !important;
    letter-spacing: -0.05em;
    text-align: left;
    color: white;
    
}

.appbar-designation{
    font-size: 12px !important;
    font-family: "Open Sans", serif !important;
    color: white;

}

.title-box{
    line-height: 15px !important;
    text-align: left !important;
    margin-left: 10px;
}


.box-content{
    width: 90%;
    border: 1px solid #000000;
    padding: 5px;
    height: 100px;
    border-radius: 20px;
}
.box{
    margin-bottom: 20px !important;
}

.box-title{
    font-size: 16px;
    padding: 2px;
    padding-top: 4px;
    height: 20px;    
    color: white;
    border-radius: 30px;
    background-color: #676DFA;
    font-weight: 500;
    line-height: 14px;
    text-align: center;
}

.box-sub-titlle{
    font-size: 14px;
    padding: 2px;
    padding-top: 4px;
    height: 20px;    
    color: #676DFA;
    border-radius: 30px;
    font-weight: 500;
    line-height: 14px;
    text-align: center;
}

.box-sub-content{
    font-size: 18px;
    padding: 2px;
    margin-top: 5px;
    padding-top: 4px;
    height: 20px;
    border-radius: 30px;
    font-weight: 500;
    line-height: 14px;
    text-align: center;
}