.App{
    background-color: brown;
}
.logo-section{
    margin-top: 136px;
    margin-bottom: 63px;
}
.logo{
   background-image: url("../../../assets/images/RMS_Logo.png");
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   height: 50px;
   width: 50px;
}

.no-record-image{
    background-image: url("../../../assets/images/norecordfound.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px;
    width: 500px; 
}

.no-record{
    background-image: url("../../../assets/images/norecordfound.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    
    margin: auto;
}

.logo-img{
    background-image: url("../../../assets/images/RMS_Logo.png");
}
.text-section{
    font-weight: 700;
    font-size: 40px;
    line-height: 46.88px;
    text-transform: uppercase;
}
.text-section2{
    margin-top: 25px;
    font-weight: 500;
    font-size: 24px;
    line-height: 28.13px;
    text-transform: uppercase;
}

.linkBtn{
    color: white !important;
    text-decoration: none;
    margin-top: 5px;
    margin-left: 15px;
}
.textSection{
    background-color: white;
    color: white;
    height: 100vh;
    width: 100%;
    /* padding-left: 113px; */
    font-family: "Open Sans" , serif;
    background-image: url("../../../assets/images/login.png");
    /* Center and scale the image nicely */
    /* background-position: center; */
    background-repeat: no-repeat;
    /* background-size:auto; */
}
.signInSection{    
    height: 100vh;
    /* text-align: center; */
    padding-top: 154px;
    padding-left: 100px;
    padding-right: 170px;
}

.signIn{
    font-weight: 600;
    font-size: 28px;
    line-height: 33.6px;
    color: #000000;
    font-family: sans-serif;
}

.signIn-desc{
    margin-top: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.2px;
    color: #000000;    
    font-family: "Open Sans", sans-serif;
}

.login-section{
    margin-top: 30px;
    margin-bottom: 30px;
}

.label{
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19.2px;
    color: #000000;
    font-family: "Open Sans", sans-serif;
}

.textfield{
    width: 100%;
    height: 54px;
    border: 1px;
    border-radius: 25px !important;
}

.password-section{
    margin-top: 30px;
}

.forgot-section{
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
}

.forgotTxt{
    color: #000000;
    font-weight: 600;
    float: right;
    font-size: 16px;
    line-height: 19.2px;
    font-family: "Inter", sans-serif;
}

.loginBtn{
    background-color: #5257C7 !important;
    color: white !important;
    text-transform: none !important;
    text-align: center;
    height: 54px !important;
    width: 100%;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 19.36px !important;
    font-family: "Inter", sans-serif  !important;
    border-radius: 10px !important;
}

.checkbox{
    width: 18px;
    height: 18px;
    margin-right: 8px;
}

.rememberTxt{
    font-weight: 400;
    font-size: 14px;
    line-height: 16.8px;
    color: #000000;
    font-family: "Open Sans", sans-serif;
    display: flex;
    align-items: center;    
}


@media only screen and (max-width: 600px) {
    .text-section{
        display: none;
    }
    .text-section2{
        display: none;
    }
    .textSection{
        background-color: black;
        height: 200px;
    }
    .logo-section{
        margin-top: 70px;
        margin-bottom: 150px;
    }
    .signInSection{
        padding-top: 50px;
        padding-left: 50px;
        padding-right: 50px;
    }
}